<template>
	<div>
		<div v-if="show==1" class="content">
			<div class="help-wrap">
				<el-breadcrumb separator="/" class="path">
					<el-breadcrumb-item :to="{ path: '/' }" class="path-home">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/cms/mysell' }" class="path-help">我要卖</el-breadcrumb-item>
					<el-breadcrumb-item class="path-help">选择区服</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

			<div class="title">当前游戏为</div>
			<div class="content_list">
				<div class="item">{{name}}</div>
				<div class="item">
					<el-button @click="backUrl()">重新选择</el-button>
				</div>
			</div>
			<div class="title">选择区服</div>
			<div class="title">请选择大区</div>
			<div class="title">请选择服务器</div>

			<div style="width: 100%;text-align: center;">
				<el-button @click="goToEdit()" type="danger" style="width: 300px;height: 50px;font-size: 16px;">下一步
					填写商品信息
				</el-button>
			</div>
		</div>


		<!--添加商品信息提交信息-->

		<div v-else class="content">
			<div class="help-wrap">
				<el-breadcrumb separator="/" class="path">
					<el-breadcrumb-item :to="{ path: '/' }" class="path-home">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/cms/mysell' }" class="path-help">我要卖</el-breadcrumb-item>
					<el-breadcrumb-item class="path-help">填写信息</el-breadcrumb-item>
				</el-breadcrumb>
			</div>


			<div class="title">当前游戏为</div>
			<div class="content_list">
				<div class="item">{{name}}</div>
				<div class="item">
					<el-button @click="backUrl()">重新选择</el-button>
				</div>
			</div>


			<div class="title" style="margin-bottom: 20px;">基本信息</div>

			<el-form ref="form" :rules="rules" :model="form" label-width="80px">
				<el-form-item label="商品标题" style="width: 50%;" prop="name">
					<el-input v-model="form.name" placeholder="请输入商品标题"></el-input>
				</el-form-item>
				<el-form-item label="封面图" prop="goodsPic">
					<el-upload class="avatar-uploader" :action="uploadActionUrl" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="form.goodsPic" :src="$img(form.goodsPic)" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>

				<el-form-item label="商品价格" style="width: 20%;" prop="price">
					<el-input v-model="form.price" placeholder="请输入商品价格">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>

				<el-form-item label="详情图" style="width: 50%;">
					<el-upload class="upload-demo" :action="uploadActionUrl" :on-preview="handlePreview"
						:on-remove="handleRemove" multiple :limit="9" :file-list="form.fileList" list-type="picture"
						:on-success="handleAvatarSuccessAll" :before-upload="beforeAvatarUpload">
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2mb</div>
					</el-upload>
				</el-form-item>
				<el-form-item label="商品描述">
					<el-input type="textarea" v-model="form.desc"></el-input>
				</el-form-item>

				<div class="title" style="margin-bottom: 20px;">账号信息</div>

				<el-form-item label="游戏账号" style="width: 50%;" prop="game_id">
					<el-input v-model="form.game_id" placeholder="请输入游戏账号"></el-input>
				</el-form-item>

				<el-form-item label="账号来源">
					<el-radio-group v-model="form.resource">
						<el-radio label="原号主"></el-radio>
						<el-radio label="购买其他平台"></el-radio>
						<el-radio label="私下交易"></el-radio>
					</el-radio-group>
				</el-form-item>

				<div class="title" style="margin-bottom: 20px;">保证信息</div>
				<el-form-item label="联系电话" style="width: 50%;" prop="phone">
					<el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
				</el-form-item>
				<el-form-item label="联系微信" style="width: 50%;" prop="wechat">
					<el-input v-model="form.wechat" placeholder="请输入联系微信"></el-input>
				</el-form-item>
				<el-form-item label="联系QQ" style="width: 50%;" prop="qq">
					<el-input v-model="form.qq" placeholder="请输入联系QQ"></el-input>
				</el-form-item>



				<el-form-item>
					<el-button type="primary" @click="submitForm('form')">提交</el-button>
					<el-button>取消</el-button>
				</el-form-item>
			</el-form>
		</div>

	</div>
</template>

<script>
	import {
		goodsUpload
	} from "@/api/goods/goods";
	import Config from '@/utils/config';

	var checkCount = function(rule, val, callback) {
		if (!val) {
			return callback(new Error("数量不能为空"));
		}
		var reg = /^(?!(0[0-9]{0,}$))[0-9]{1,}(\.{0,1}\d{1,3}){0,1}$/;
		if (!reg.test(val)) {
			return callback(new Error("格式错误,至多三位小数"));
		}
		return callback();
	};
	var checkPrice = function(rule, val, callback) {
		if (!val) {
			return callback(new Error("单价不能为空"));
		}
		var reg = /^\d+(\.{0,1}\d{1,4}){0,1}$/;
		if (!reg.test(val)) {
			return callback(new Error("格式错误,至多四位小数"));
		}
		return callback();
	};
	var checkScore = function(rule, val, callback) {
		if (!val) {
			return callback(new Error("分值不能为空"));
		}
		var reg = /^\d+(\.{0,1}\d{1,2}){0,1}$/;
		if (!reg.test(val)) {
			return callback(new Error("格式错误,至多两位小数"));
		}

		if (parseFloat(val) > 100) {
			return callback(new Error("分值最大只能是100"));
		}
		return callback();
	};

	export default {
		name: "sell",
		components: {},
		data: () => {
			return {
				key: '',
				show: 1,
				form: {
					name: '',
					goodsPic: '',
					price: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: '',
					imageUrl: '',
					fileList: [],
					fileListEnd: [],
					game_id: '',
					phone: '',
					wechat: '',
					qq: ''

				},

				rules: {
					name: [{
							required: true,
							message: '请输入名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 1000,
							message: '长度在 1 到 1000 个字符',
							trigger: 'blur'
						}
					],
					goodsPic: [{
						required: true,
						message: '上传封面图',
						trigger: 'blur'
					}],
					price: [{
							required: true,
							message: '请输入价格',
							trigger: 'blur'
						},
						{
							validator: checkPrice,
							min: 1,
							max: 10,
							message: '请输入正确的金额',
							trigger: 'blur'
						}
					],

					phone: [{
							required: true,
							message: '请输入联系方式',
							trigger: 'change'
						},
						{
							min: 8,
							max: 13,
							message: '请输入正确的联系方式',
							trigger: 'blur'
						}
					],

					qq: [{

							required: true,
							message: '请输入QQ号码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 15,
							message: '请输入正确的QQ号码',
							trigger: 'blur'
						}
					],
					wechat: [{
							required: true,
							message: '请输入微信号',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 30,
							message: '请输入正确的微信号',
							trigger: 'blur'
						}
					],
				},

				imageUrl: '',
				category_id: '',
				level: '',
				name: '',
				uploadActionUrl: Config.baseUrl + '/api/upload/goodsimgupload' + '?app_type=pc',
			}
		},
		created() {
			let query = this.$route.query;
			let name = query.name;
			this.category_id = query.category_id;
			this.level = query.level;
			this.name = query.name;
		},
		mounted() {

		},
		onLoad() {
			console.log('11');
		},
		methods: {
			/**
			 * 跳转对应的页面
			 */
			goToEdit(_id) {
				this.show = 2;
			},
			backUrl() {
				history.go(-1);
			},

			onSubmit() {

			},
			/**
			 * 图片上传
			 */
			handleAvatarSuccess(res, file) {
				this.form.goodsPic = res.data.pic_path;
				// this.imageUrl = URL.createObjectURL(file.raw);
				// console.log(res.data.pic_path,URL.createObjectURL(file.raw));
				// this.memberInfo.userHeadImg = URL.createObjectURL(file.raw);
			},

			beforeAvatarUpload(file) {
				console.log(file);
				const isJPG = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG/JPEG/PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},

			/**
			 * 更多图片上传
			 */

			handleRemove(file, fileList) {
				Array.prototype.remove = function(val) {
					var index = this.indexOf(val);
					if (index > -1) {
						this.splice(index, 1);
					}
				}

				var res = file.response;
				this.form.fileListEnd.remove(res.data.pic_path);
			},
			handlePreview(file) {
				// console.log('111',file);
			},

			handleAvatarSuccessAll(res, file) {
				if (res.message == "上传成功") {
					this.form.fileListEnd.push(res.data.pic_path);
				}
			},

			/**
			 * 商品上传
			 * 上传
			 */

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.goGoodsUpload();
					} else {
						this.$message({
							message: '请准确填写信息',
							type: 'warning'
						});
						return false;
					}
				});
			},

			goGoodsUpload() {
				goodsUpload({
						category_id: this.category_id,
						category_name: this.name,
						goods_name: this.form.name,
						goods_image: this.form.goodsPic,
						goods_image_arr: this.form.fileListEnd.toString(),
						goods_content: this.form.desc,
						price: this.form.price,
						goods_game_id: this.form.game_id,
						goods_from: this.form.resource,
						phone: this.form.phone,
						wechat: this.form.wechat,
						qq: this.form.qq,
					})
					.then(res => {
						if (res.code == 0) {
							this.$message({
								message: '提交成功',
								type: 'success'
							});

							this.$alert('提交成功', '消息提示', {
								confirmButtonText: '确定',
								callback: action => {
									// this.$message({
									// 	type: 'info',
									// 	message: `action: ${ action }`
									// });
								}
							});

							this.form = {
								name: '',
								goodsPic: '',
								price: '',
								region: '',
								date1: '',
								date2: '',
								delivery: false,
								type: [],
								resource: '',
								desc: '',
								imageUrl: '',
								fileList: [],
								fileListEnd: [],
								game_id: '',
								phone: '',
								wechat: '',
								qq: ''
							}

						} else {
							this.$message.error('提交失败');
						}
					})
					.catch(err => {
						this.$message.error(err.message)
						this.loading = false
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.content {
		min-height: 500px;

		.title {
			width: 100%;
			height: 40px;
			line-height: 40px;
			font-size: 18px;
			font-weight: bold;
			background-color: rgb(102, 177, 255);
			padding-left: 20px;
			border-radius: 5px;
			color: #fff;
			margin: 10px 0px 10px 0px;
		}
	}

	.help-wrap {
		background: #ffffff;

		.path {
			padding: 15px 0;
		}
	}


	.content_list {
		padding: 20px;
		font-size: 20px;

		.item {
			display: inline-block;
			margin-right: 30px;
		}
	}


	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
